<template>
<span class="DeviceDiagnose">
    <el-button :type="btnType" size="small" @click="handleDialogShow" :plain='plain'>{{btnText}}</el-button>
    <el-dialog title="设备诊断" width="504px" :visible.sync="dialogVisible" :before-close="handleDialogClose" custom-class="device-diagnose-dialog">
        <div class="form-item">
            <div class="key">设备序列号</div>
            <div class="value">{{diagnose.deviceSerial}}</div>
        </div>
        <div class="form-item">
            <div class="key">设备名称</div>
            <div class="value">{{diagnose.deviceName}}</div>
        </div>
        <div class="form-item">
            <div class="key">设备型号</div>
            <div class="value">{{diagnose.model}}</div>
        </div>
        <div class="form-item">
            <div class="key">设备状态</div>
            <div class="value">{{diagnose.status ? '在线' : '不在线'}}</div>
        </div>
        <div class="form-item">
            <div class="key">布撤防状态</div>
            <div class="value">{{diagnose.defence | defenceFilter}}</div>
        </div>
        <div class="form-item">
            <div class="key">是否加密</div>
            <div class="value">{{diagnose.isEncrypt ? '加密' : '不加密'}}</div>
        </div>
        <div class="form-item">
            <div class="key">告警声音模式</div>
            <div class="value">{{diagnose.alarmSoundMode | alarmSoundFilter}}</div>
        </div>
        <div class="form-item">
            <div class="key">下线通知</div>
            <div class="value">{{diagnose.offlineNotify ? '通知' : '不通知'}}</div>
        </div>
        <div class="form-item">
            <div class="key">设备大类</div>
            <div class="value">{{diagnose.parentCategory}}/{{diagnose.category}}</div>
        </div>
        <div class="form-item" v-if="diagnose.netType !== null">
            <div class="key">网络类型</div>
            <div class="value">{{diagnose.netType}}</div>
        </div>
        <div class="form-item" v-if="diagnose.signal !== null">
            <div class="key">信号强度</div>
            <div class="value">{{diagnose.signal}}</div>
        </div>
        <span slot="footer" class="dialog-footer" style="display: flex;justify-content: center">
            <el-button type="primary" @click="handleDialogClose">确定</el-button>
        </span>
    </el-dialog>
</span>

</template>

<script>
import Core from '@/core';
export default {
    components: {},
    props: {
        item: {
            type: Object,
        },
        btnType: {
            type: String,
            default: 'text'
        },
        btnText: {
            type: String,
            default: '诊断'
        },
    },
    data() {
        return {
            dialogVisible: false,
            diagnose: {},
        }
    },
    watch: {},
    computed: {},
    filters: {
        defenceFilter(key) {
            switch (key) {
                case 0: return '撤防'
                case 1: return '布防'
                case 8: return '在家'
                case 16: return '外出'
            }
        },
        alarmSoundFilter(key) {
            switch (key) {
                case 0: return '短叫'
                case 1: return '长叫'
                case 2: return '静音'
            }
        },
    },
    created() {},
    mounted() {},
    methods: {
        handleDialogShow() {
            Core.Api.Device.diagnose(this.item.serial_number).then(res => {
                console.log('getDeviceDiagnose res:', res)
                this.diagnose = res.result
                this.dialogVisible = true;
            }).catch()
        },
        handleDialogClose() {
            this.dialogVisible = false;
        },
    },
}
</script>

<style lang='scss'>
.device-diagnose-dialog {
    .form-item {
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: center;
        min-height: 32px;
        + .form-item {
            margin-top: 16px;
        }
        .key {
            color: #8090a6;
            font-size: 14px;
            width: 120px;
            text-align: right;
            box-sizing: border-box;
            padding-right: 20px;
        }
        .value {
            width: calc(100% - 120px);
            box-sizing: border-box;
            padding-right: 20px;
        }
        &.channel-name {
            align-items: flex-start;
            .key {
                line-height: 32px;
            }
            .value {
                .form-item-channel {
                    width: 100%;
                    display: flex;
                    align-items: center;
                    .key-channel {
                        width: 80px;
                        box-sizing: border-box;
                        padding-left: 4px;
                        font-size: 14px;
                    }
                    .value-channel {
                        width: calc(100% - 80px);
                    }
                    + .form-item-channel {
                        margin-top: 16px;
                    }
                }
            }
        }
    }
    .el-dialog__body {
        max-height: 60vh;
        overflow-y: auto;
    }
}
</style>